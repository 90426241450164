/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

import { localeData } from 'moment';

/* eslint-disable */

// This file used to be a json file, but had issues with importing, so we declared the json variable in a js file instead.
const localeData = {
	"en": {
		"400": "400 Bad Request",
		"404": "404 Not Found",
		"4.weeks": "4 Weeks",
		"action": "Action",
		"add.new.meters": "Add new meters",
		"admin.only": "Admin Only",
		"admin.panel": "Admin Panel",
		"alphabetically": "Alphabetically",
		"area": "Area:",
		"area.but.no.unit": "You have entered a nonzero area but no area unit.",
		"area.error": "Please enter a number for area",
		"area.normalize": "Normalize by Area",
		"area.calculate.auto": "Calculate Group Area",
		"area.unit": "Area Unit:",
		"AreaUnitType.feet": "sq. feet",
		"AreaUnitType.meters": "sq. meters",
		"AreaUnitType.none": "no unit",
		"ascending": "Ascending",
		"bar": "Bar",
		"bar.interval": "Bar Interval",
		"bar.raw": "Cannot create bar graph on raw units such as temperature",
		"bar.stacking": "Bar Stacking",
		"BooleanMeterTypes.false": "no",
		"BooleanMeterTypes.meter": "meter or default value",
		"BooleanMeterTypes.true": "yes",
		"calibration.display": "result:",
		"calibration.reset.button": "Reset",
		"calibration.save.database": "Save changes to database",
		"calibration.submit.button": "Submit",
		"cancel": "Cancel",
		"child.groups": "Child Groups",
		"child.meters": "Child Meters",
		"close": "Close",
		"compare": "Compare",
		"compare.raw": "Cannot create comparison graph on raw units such as temperature",
		"confirm.action": "Confirm Action",
		"contact.us": "Contact us",
		"conversion": "Conversion",
		"conversions": "Conversions",
		"ConversionType.conversion": "conversion",
		"conversion.bidirectional": "Bidirectional:",
		"conversion.create.destination.meter": "The destination cannot be a meter",
		"conversion.create.exists": "This conversion already exists",
		"conversion.create.exists.inverse": "This conversion already exists where one is not bidirectional",
		"conversion.create.mixed.represent": "A conversion cannot mix units of quantity, flow and raw",
		"conversion.create.source.destination.same": "The source and destination cannot be the same for a conversion",
		"conversion.delete.conversion": "Delete Conversion",
		"conversion.destination": "Destination:",
		"conversion.dropdown.displayable.option.admin": "Admin",
		"conversion.edit.conversion": "Edit Conversion",
		"conversion.failed.to.create.conversion": "Failed to create a conversion.",
		"conversion.failed.to.delete.conversion": "Failed to delete conversion",
		"conversion.failed.to.edit.conversion": "Failed to edit conversion.",
		"conversion.intercept": "Intercept:",
		"conversion.is.deleted": "Conversion removed from database",
		"conversion.represent": "Conversion Represent:",
		"conversion.select.destination": "Select a destination unit",
		"conversion.select.source": "Select a source unit",
		"conversion.slope": "Slope:",
		"conversion.source": "Source:",
		"conversion.submit.new.conversion": "Submit New Conversion",
		"conversion.successfully.create.conversion": "Successfully created a conversion.",
		"conversion.successfully.delete.conversion": "Successfully deleted conversion.",
		"conversion.successfully.edited.conversion": "Successfully edited conversion.",
		"create.conversion": "Create a Conversion",
		"create.group": "Create a Group",
		"create.map": "Create a Map",
		"create.user": "Create a User",
		"create.unit": "Create a Unit",
		"csv": "CSV",
		"csv.file": "CSV File",
		"csv.common.param.gzip": "Gzip",
		"csv.common.param.header.row": "Header Row",
		"csv.common.param.update": "Update",
		"csv.download.size.limit": "Sorry you don't have permissions to download due to large number of points.",
		"csv.download.size.warning.size": "Total size of all files will be about (usually within 10% for large exports).",
		"csv.download.size.warning.verify": "Are you sure you want to download",
		"csv.readings.param.create.meter": "Create Meter",
		"csv.readings.param.cumulative": "Cumulative",
		"csv.readings.param.cumulative.reset": "Cumulative Reset",
		"csv.readings.param.cumulative.reset.end": "Cumulative Reset End",
		"csv.readings.param.cumulative.reset.start": "Cumulative Reset Start",
		"csv.readings.param.duplications": "Duplications",
		"csv.readings.param.endOnly": "End Only times",
		"csv.readings.param.honor.dst": "Honor Daylight Savings Time",
		"csv.readings.param.lengthGap": "Length Gap",
		"csv.readings.param.length.variation": "Length Variation",
		"csv.readings.param.meter.name": "Meter name",
		"csv.readings.param.refresh.hourlyReadings": "Refresh Hourly Readings",
		"csv.readings.param.refresh.readings": "Refresh Daily Readings",
		"csv.readings.param.relaxed.parsing": "Relaxed Parsing",
		"csv.readings.param.time.sort": "Time Sort",
		"csv.readings.section.cumulative.data": "Cumulative Data",
		"csv.readings.section.time.gaps": "Time Gaps",
		"csv.submit.button": "Submit CSV Data",
		"csv.tab.meters": "Meters",
		"csv.tab.readings": "Readings",
		"csv.upload.meters": "Upload Meters CSV ",
		"csv.upload.readings": "Upload Readings CSV ",
		"day": "Day",
		"days": "Days",
		"decreasing": "decreasing",
		"default.area.normalize": "Normalize readings by area by default",
		"default.area.unit": "Default Area Unit",
		"default.bar.stacking": "Stack bars by default",
		"default.graph.type": "Default Graph Type",
		"default.graph.settings": "Default Graph Settings",
		"defaultGraphicUnit": "Default Graphic Unit:",
		"default.language": "Default Language",
		"default.meter.reading.frequency": "Default meter reading frequency",
		"default.site.title": "Default Site Title",
		"default.warning.file.size": "Default Warning File Size",
		"default.file.size.limit": "Default File Size Limit",
		"default.time.zone": "Default Time Zone",
		"delete.group": "Delete Group",
		"delete.map": "Delete Map",
		"delete.user": "Delete User",
		"descending": "Descending",
		"discard.changes": "Discard Changes",
		"disable": "Disable",
		"displayable": "Displayable:",
		"DisplayableType.none": "none",
		"DisplayableType.all": "all",
		"DisplayableType.admin": "admin",
		"error.bounds": "Must be between {min} and {max}.",
		"error.displayable": "Displayable will be set to false because no unit is selected.",
		"error.displayable.meter": "Meter units will set displayable to none.",
		"error.greater": "Must be greater than {min}.",
		"error.gps": "Latitude must be between -90 and 90, and Longitude must be between -180 and 180.",
		"error.negative": "Cannot be negative.",
		"error.required": "Required field.",
		"edit": "Edit",
		"edited": "edited",
		"edit.a.group": "Edit a Group",
		"edit.a.meter": "Edit a Meter",
		"edit.group": "Edit Group",
		"edit.meter": "Edit Meter",
		"edit.unit": "Edit Unit",
		"email": "Email",
		"enable": "Enable",
		"export.graph.data": "Export graph data",
		"export.raw.graph.data": "Export graph meter data",
		"failed.logging.in": "Failed logging in",
		"failed.to.create.map": "Failed to create map",
		"failed.to.delete.group": "Failed to delete group",
		"failed.to.delete.map": "Failed to delete map",
		"failed.to.edit.map": "Failed to edit map",
		"failed.to.link.graph": "Failed to link graph",
		"failed.to.submit.changes": "Failed to submit changes",
		"false": "False",
		"gps": "GPS: latitude, longitude",
		"graph": "Graph",
		"graph.type": "Graph Type",
		"group": "Group",
		"group.all.meters": "All Meters",
		"group.area.calculate": "Calculate Group Area",
		"group.area.calculate.header": "Group Area will be set to ",
		"group.area.calculate.error.header": "The following meters were excluded from the sum because:",
		"group.area.calculate.error.zero": ": area is unset or zero",
		"group.area.calculate.error.unit": ": nonzero area but no area unit",
		"group.area.calculate.error.no.meters": "No meters in group",
		"group.area.calculate.error.group.unit": "No group area unit",
		"group.create.nounit": "The default graphic unit was changed to no unit from ",
		"group.delete.group": "Delete Group",
		"group.delete.issue": "is contained in the following groups and cannot be deleted",
		"group.details": "Group Details",
		"group.edit.cancelled": "THE CHANGE TO THE GROUP IS CANCELLED",
		"group.edit.changed": "will have its compatible units changed by the edit to this group",
		"group.edit.circular": "Adding this meter/group to this group creates a circular dependency.",
		"group.edit.empty": "Removing this meter/group means there are no child meters or groups which is not allowed . Delete the group if you want to remove it.",
		"group.edit.nocompatible": "would have no compatible units by the edit to this group so the edit is cancelled",
		"group.edit.nounit": "will have its compatible units changed and its default graphic unit set to \"no unit\" by the edit to this group",
		"group.edit.verify": "Given the messages, do you want to cancel this change (click Cancel) or continue (click OK)?",
		"group.failed.to.create.group": "Failed to create a group with message: ",
		"group.failed.to.edit.group": "Failed to edit group with message: ",
		"group.gps.error": "Please input a valid GPS: (latitude, longitude)",
		"group.hidden": "At least one group is not visible to you",
		"group.input.error": "Input invalid so group not created or edited.",
		"group.name.error": "Please enter a valid name: (must have at least one character that is not a space)",
		"groups": "Groups",
		"group.successfully.create.group": "Successfully created a group.",
		"group.successfully.edited.group": "Successfully edited group.",
		"groups.select": "Select Groups",
		"has.no.data": "has no current data",
		"has.used": "has used",
		"header.pages": "Pages",
		"header.options": "Options",
		"help": "Help",
		"help.admin.conversioncreate": "This page allows admins to create conversions. Please visit {link} for further details and information.",
		"help.admin.conversionedit": "This page allows admins to edit conversions. Please visit {link} for further details and information.",
		"help.admin.conversionview": "This page shows information on conversions. Please visit {link} for further details and information.",
		"help.admin.groupcreate": "This page allows admins to create groups. Please visit {link} for further details and information.",
		"help.admin.groupedit": "This page allows admins to edit groups. Please visit {link} for further details and information.",
		"help.admin.groupview": "This page allows admins to view groups. Please visit {link} for further details and information.",
		"help.admin.header": "This page is to allow site administrators to set values and manage users.  Please visit {link} for further details and information.",
		"help.admin.mapview": "This page allows admins to view and edit maps. Please visit {link} for further details and information",
		"help.admin.metercreate": "This page allows admins to create meters. Please visit {link} for further details and information.",
		"help.admin.meteredit": "This page allows admins to edit meters. Please visit {link} for further details and information.",
		"help.admin.meterview": "This page allows admins to view and edit meters. Please visit {link} for further details and information.",
		"help.admin.unitcreate": "This page allows admins to create units. Please visit {link} for further details and information.",
		"help.admin.unitedit": "This page allows admins to edit units. Please visit {link} for further details and information.",
		"help.admin.unitview": "This page shows information on units. Please visit {link} for further details and information.",
		"help.admin.user": "This page allows admins to view and edit users. Please visit {link} for further details and information.",
		"help.csv.header": "This page allows certain users to upload meters and readings via a CSV file. Please visit {link} for further details and information.",
		"help.groups.groupdetails": "This page shows detailed information on a group. Please visit {link} for further details and information.",
		"help.groups.groupview": "This page shows information on groups. Please visit {link} for further details and information.",
		"help.groups.area.calculate": "This will sum together the area of all meters in this group with a nonzero area and a set area unit. It will ignore any meters which have no area or area unit. If this group has no area unit, it will do nothing.",
		"help.home.area.normalize": "Toggles normalization by area. Meters/Groups without area will be hidden. Please visit {link} for further details and information.",
		"help.home.bar.custom.slider.tip": "Allows user to select the desired number of days for each bar.  Please see {link} for further details and information.",
		"help.home.bar.interval.tip": "Selects the time interval (Day, Week or 4 Weeks) for each bar.  Please see {link} for further details and information.",
		"help.home.bar.stacking.tip": "Bars stack on top of each other. Please see {link} for further details and information.",
		"help.home.map.interval.tip": "Selects the time interval (the last Day, Week or 4 Weeks) for map corresponding to bar's time interval. Please see {link} for further details and information.",
		"help.home.chart.plotly.controls": "These controls are provided by Plotly, the graphics package used by OED. You generally do not need them but they are provided in case you want that level of control. Note that some of these options may not interact nicely with OED features. See Plotly documentation at {link}.",
		"help.home.chart.redraw.restore": "OED automatically averages data when necessary so the graphs have a reasonable number of points. If you use the controls under the graph to scroll and/or zoom, you may find the resolution at this averaged level is not what you desire. Clicking the \"Redraw\" button will have OED recalculate the averaging and bring in higher resolution for the number of points it displays. If you want to restore the graph to the full range of dates, then click the \"Restore\" button. Please visit {link} for further details and information.",
		"help.home.chart.select": "Any graph type can be used with any combination of groups and meters. Line graphs show the usage (e.g., kW) vs. time. You can zoom and scroll with the controls right below the graph. Bar shows the total usage (e.g., kWh) for the time frame of each bar where you can control the time frame. Compare allows you to see the current usage vs. the usage in the last previous period for a day, week and four weeks. Map graphs show a spatial image of each meter where the circle size is related to four weeks of usage. Clicking on one of the choices (Line, Bar, Compare, Map) renders that graphic. Please visit {link} for further details and information.",
		"help.home.compare.interval.tip": "Selects the time interval (Day, Week or 4 Weeks) to compare for current to previous.  Please see {link} for further details and information.",
		"help.home.compare.sort.tip": "Allows user to select the order of multiple comparison graphs to be Alphabetical (by name), Ascending (greatest to least reduction in usage) and Descending (least to greatest reduction in usage).  Please see {link} for further details and information.",
		"help.home.export.graph.data": "With the \"Export graph data\" button, one can export the data for the graph when viewing either a line or bar graphic. The zoom and scroll feature on the line graph allows you to control the time frame of the data exported. The \"Export graph data\" button gives the data points for the graph and not the original meter data. The \"Export graph meter data\" gives the underlying meter data (line graphs only). Please visit {link} for further details and information on when meter data export is allowed.",
		"help.home.navigation": "The \"Graph\" button goes to the graphic page, the \"Pages\" dropdown allows navigation to information pages, the \"Options\" dropdown allows selection of language, hide options and login/out and the \"Help\" button goes to the help pages. See help on the dropdown menus or the linked pages for further information.",
		"help.home.select.groups": "Groups aggregate (sum the usage) of any combination of groups and meters. You can choose which groups to view in your graphic from the \"Groups:\" dropdown menu. Note you can type in text to limit which groups are shown. The \"Groups\" option in the top, right \"Pages\" dropdown allows you to see more details about each group and, if you are an admin, to edit the groups. Please visit {link} for further details and information.",
		"help.home.select.maps": "Maps are a spatial representation of a site. You can choose which map to view from the \"Maps:\" dropdown menu. Note you can type in text to limit which maps are shown. Please visit {link} for further details and information.",
		"help.home.select.meters": "Meters are the basic unit of usage and generally represent the readings from a single usage meter. You can choose which meters to view in your graphic from the \"Meters:\" dropdown menu. Note you can type in text to limit which meters are shown. The \"Meters\" option in the top, right \"Pages\" dropdown allows you to see more details about each meter and, if you are an admin, to edit the meters. Please visit {link} for further details and information.",
		"help.home.select.rates": "Rates determine the time normalization for a line graph. Please visit {link} for further details and information",
		"help.home.select.units": "Units determine the values displayed in a graphic. Please visit {link} for further details and information",
		"help.home.toggle.chart.link": "With the \"Toggle chart link\" button a box appears that gives a URL that will recreate the current graphic. The link will recreate the graphic in the future, esp. for others to see. The person using this URL will be in a fully functional OED so they can make changes after the original graphic is displayed. You can help discourage changes by choosing the option to hide the option choice so they are not visible when using the URL. Please visit {link} for further details and information.",
		"help.meters.meterview": "This page shows information on meters. Please visit {link} for further details and information.",
		"here": "here",
		"hide": "Hide",
		"hide.options": "Hide options",
		"hide.options.in.link": "Hide options in link",
		"home": "Home",
		"hour": "Hour",
		"identifier": "Identifier:",
		"import.meter.readings": "Import Meter Readings",
		"incompatible.groups": "Incompatible Groups",
		"incompatible.meters": "Incompatible Meters",
		"incompatible.units": "Incompatible Units",
		"increasing": "increasing",
		"info": " for more information. ",
		"input.gps.coords.first": "input GPS coordinate that corresponds to the point: ",
		"input.gps.coords.second": "in this format -> latitude,longitude",
		"input.gps.range": "Invalid GPS coordinate, latitude must be an integer between -90 and 90, longitude must be an integer between -180 and 180. You input: ",
		"insufficient.readings": "Insufficient readings data to process comparison for ",
		"invalid.email.password": "Invalid email/password combination",
		"invalid.number": "Please submit a valid number (between 0 and 2.0)",
		"invalid.token.login": "Token has expired. Please log in again.",
		"invalid.token.login.admin": "Token has expired. Please log in again to view this page.",
		"language": "Language",
		"last.four.weeks": "Last four weeks",
		"last.week": "Last week",
		"leave": "Leave",
		"less.energy": "less energy",
		"line": "Line",
		"log.in": "Log in",
		"log.out": "Log out",
		"logo": "Logo",
		"manage": "Manage",
		"map": "Map",
		"maps": "Maps",
		"map.bad.number": "Not a number, please change angle to a number between 0 and 360",
		"map.bad.digita": "Greater than 360, please change angle to a number between 0 and 360",
		"map.bad.digitb": "Less than 0, please change angle to a number between 0 and 360",
		"map.calibrate": "Calibrate",
		"map.calibration": "Calibration status",
		"map.circle.size": "Map Circle Size",
		"map.confirm.remove": "Are you sure you want to remove map",
		"map.displayable": "Map Display",
		"map.filename": "Map file",
		"map.id": "Map ID",
		"map.interval": "Map Interval",
		"map.is.calibrated": "Calibration Complete",
		"map.is.deleted": "Map removed from database",
		"map.is.displayable": "Display Enabled",
		"map.is.not.calibrated": "Calibration Needed",
		"map.is.not.displayable": "Display Disabled",
		"map.load.complete": "Map load complete from",
		"map.modified.date": "Last Modified",
		"map.name": "Map Name",
		"map.new.angle": "List the angle with respect to true north (0 to 360)",
		"map.new.name": "Define a name for the map:",
		"map.new.submit": "Save and continue",
		"map.new.upload": "Upload map image to begin.",
		"map.notify.calibration.needed": "Calibration needed before display",
		"map.upload.new.file": "Redo",
		"menu": "Menu",
		"meter": "Meter",
		"meters": "Meters",
		"meter.create": "Create a Meter",
		"meter.cumulative": "Cumulative:",
		"meter.cumulativeReset": "Cumulative Reset:",
		"meter.cumulativeResetEnd": "Cumulative Reset End:",
		"meter.cumulativeResetStart": "Cumulative Reset Start:",
		"meter.enabled": "Updates:",
		"meter.endOnlyTime": "End Only Time:",
		"meter.endTimeStamp": "End Time Stamp:",
		"meter.failed.to.create.meter": "Failed to create a meter with message: ",
		"meter.failed.to.edit.meter": "Failed to edit meter with message: ",
		"meter.hidden": "At least one meter is not visible to you",
		"meter.id": "ID",
		"meter.input.error": "Input invalid so meter not created or edited.",
		"meter.unit.change.requires": "needs to be changed before changing this unit's type",
		"meter.unitName": "Unit:",
		"meter.url": "URL:",
		"meter.is.displayable": "Display Enabled",
		"meter.is.enabled": "Updates Enabled",
		"meter.is.not.displayable": "Display Disabled",
		"meter.is.not.enabled": "Updates Disabled",
		"meter.previousEnd": "Previous End Time Stamp:",
		"meter.readingDuplication": "Reading Duplication:",
		"meter.readingFrequency": "Reading Frequency",
		"meter.readingGap": "Reading Gap:",
		"meter.readingVariation": "Reading Variation:",
		"meter.startTimeStamp": "Start Time Stamp:",
		"meter.successfully.create.meter": "Successfully created a meter.",
		"meter.successfully.edited.meter": "Successfully edited meter.",
		"meter.timeSort": "Time Sort:",
		"meter.time.zone": "Time Zone:",
		"meter.type": "Type:",
		"minute": "Minute",
		"more.energy": "more energy",
		"name": "Name:",
		"navigation": "Navigation",
		"need.more.points": "Need more points",
		"no": "no",
		"note": "Note:",
		"oed": "Open Energy Dashboard",
		"oed.description": "Open Energy Dashboard is an independent open source project. ",
		"oed.version": "OED version ",
		"options": "Options",
		"page.choice.login": "Page choices & login",
		"page.choice.logout": "Page choices & logout",
		"password": "Password",
		"password.confirm": "Confirm password",
		"per.day": "Per Day",
		"per.hour": "Per Hour",
		"per.minute": "Per Minute",
		"per.second": "Per Second",
		"projected.to.be.used": "projected to be used",
		"rate": "Rate",
		"reading": "Reading:",
		"redo.cik.and.refresh.db.views": "Processing changes. This may take a while.",
		"redraw": "Redraw",
		"remove": "Remove",
		"restore": "Restore",
		"role": "Role",
		"save.all": "Save all",
		"save.map.edits": "Save map edits",
		"save.meter.edits": "Save meter edits",
		"save.role.changes": "Save role changes",
		"second": "Second",
		"select.groups": "Select Groups",
		"select.map": "Select Map",
		"select.meter": "Select Meter",
		"select.meter.type": "Select Meter Type",
		"select.meter.group": "Select meter or group to graph",
		"select.meters": "Select Meters",
		"select.unit": "Select Unit",
		"show": "Show",
		"show.grid": "Show grid",
		"show.options": "Show options",
		"sort": "Sort",
		"submit": "Submit",
		"submitting": "submitting",
		"submit.changes": "Submit changes",
		"submit.new.user": "Submit new user",
		"the.unit.of.meter": "The unit of meter",
		"this.four.weeks": "These four weeks",
		"TimeSortTypes.decreasing": "decreasing",
		"TimeSortTypes.increasing": "increasing",
		"TimeSortTypes.meter": "meter value or default",
		"true": "True",
		"timezone.no": "No timezone",
		"this.week": "This week",
		"today": "Today",
		"toggle.custom.slider": "Toggle custom slider",
		"toggle.link": "Toggle chart link",
		"total": "total",
		"TrueFalseType.false": "no",
		"TrueFalseType.true": "yes",
		"undefined": "undefined",
		"unit": "Unit",
		"UnitRepresentType.quantity": "quantity",
		"UnitRepresentType.flow": "flow",
		"UnitRepresentType.raw": "raw",
		"UnitType.unit": "unit",
		"UnitType.meter": "meter",
		"UnitType.suffix": "suffix",
		"unit.dropdown.displayable.option.none": "None",
		"unit.dropdown.displayable.option.all": "All",
		"unit.dropdown.displayable.option.admin": "admin",
		"unit.failed.to.create.unit": "Failed to create a unit.",
		"unit.failed.to.edit.unit": "Failed to edit unit.",
		"unit.input.error": "Input invalid so unit not created or edited.",
		"unit.none": "no unit",
		"unit.preferred.display": "Preferred Display:",
		"unit.represent": "Unit Represent:",
		"unit.sec.in.rate": "Sec in Rate:",
		"unit.submit.new.unit": "Submit New Unit",
		"unit.successfully.create.unit": "Successfully created a unit.",
		"unit.successfully.edited.unit": "Successfully edited unit.",
		"unit.suffix": "Suffix:",
		"unit.type.of.unit": "Type of Unit:",
		"units": "Units",
		"unsaved.warning": "You have unsaved change(s). Are you sure you want to leave?",
		"update": "update",
		"updated.map.with.calibration": "Updated map with renewed calibration",
		"updated.map.without.calibration": "Updated map(uncalibrated)",
		"updated.map.without.new.calibration": "Updated map",
		"updated.preferences": "Updated preferences",
		"upload.meters.csv": "Upload meters CSV file",
		"upload.new.map.with.calibration": "Uploaded new calibrated map",
		"upload.new.map.without.calibration": "Uploaded new map(uncalibrated)",
		"upload.readings.csv": "Upload readings CSV file",
		"used.so.far": "used so far",
		"used.this.time": "used this time",
		"users": "Users",
		"users.failed.to.create.user": "Failed to create a user.",
		"users.failed.to.delete.user": "Failed to delete a user.",
		"users.failed.to.edit.users": "Failed to edit users.",
		"users.successfully.create.user": "Successfully created a user.",
		"users.successfully.delete.user": "Successfully deleted user.",
		"users.successfully.edit.users": "Successfully edited users.",
		"view.groups": "View Groups",
		"visit": " or visit our ",
		"website": "website",
		"week": "Week",
		"yes": "yes",
		"yesterday": "Yesterday",
		"you.cannot.create.a.cyclic.group": "You cannot create a cyclic group"
	},
	"fr": {
		"400": "(Need French) 400 Bad Request",
		"404": "404 Introuvable",
		"4.weeks": "4 Semaines",
		"action": "(Need French) Action",
		"add.new.meters": "Ajouter de Nouveaux Mètres",
		"admin.only": "Uniquement pour Les Administrateurs",
		"admin.panel": "Panneau d'administration",
		"alphabetically": "Alphabétiquement",
		"area": "Région:",
		"area.but.no.unit": "(Need French) You have entered a nonzero area but no area unit.",
		"area.error": "(Need French) Please enter a number for area",
		"area.normalize": "(Need French) Normalize by Area",
		"area.calculate.auto": "(Need French) Calculate Group Area",
		"area.unit": "(Need French) Area Unit:",
		"AreaUnitType.feet": "pieds carrés",
		"AreaUnitType.meters": "mètre carré",
		"AreaUnitType.none": "(Need French) no unit",
		"ascending": "Ascendant",
		"bar": "Bande",
		"bar.interval": "Intervalle du Diagramme à Bandes",
		"bar.raw": "(Need French) Cannot create bar graph on raw units such as temperature",
		"bar.stacking": "Empilage de Bandes",
		"BooleanMeterTypes.false": "(need French) yes",
		"BooleanMeterTypes.meter": "valeur du compteur ou valeur par défaut",
		"BooleanMeterTypes.true": "(need French) no",
		"calibration.display": "résultat: ",
		"calibration.reset.button": "Réinitialiser",
		"calibration.save.database": "Enregistrer les modifications dans la base de données",
		"calibration.submit.button": "Soumettre",
		"cancel": "Annuler",
		"child.groups": "Groupes Enfants",
		"child.meters": "Mètres Enfants",
		"close": "(need French) Close",
		"compare": "Comparer",
		"compare.raw": "(Need French) Cannot create comparison graph on raw units such as temperature",
		"confirm.action": "(Need French) Confirm Action",
		"contact.us": "Contactez nous",
		"conversion": "(Need French) Conversion",
		"conversions": "(Need French) Conversions",
		"ConversionType.conversion": "(Need French) conversion",
		"conversion.bidirectional": "(Need French) Bidirectional:",
		"conversion.create.destination.meter": "(Need French) The destination cannot be a meter",
		"conversion.create.exists": "(Need French) This conversion already exists",
		"conversion.create.exists.inverse": "(Need French) This conversion already exists where one is not bidirectional",
		"conversion.create.mixed.represent": "(Need French) A conversion cannot mix units of quantity, flow and raw",
		"conversion.create.source.destination.same": "(Need French) The source and destination cannot be the same for a conversion",
		"conversion.delete.conversion": "(Need French) Delete Conversion",
		"conversion.destination": "(Need French) Destination:",
		"conversion.dropdown.displayable.option.admin": "(Need French) admin",
		"conversion.edit.conversion": "(need French) Edit Conversion",
		"conversion.failed.to.create.conversion": "(Need French) Failed to create a conversion.",
		"conversion.failed.to.delete.conversion": "(Need French) Failed to delete conversion",
		"conversion.failed.to.edit.conversion": "(Need French) Failed to edit conversion.",
		"conversion.intercept": "(Need French) Intercept:",
		"conversion.is.deleted": "(Need French) Conversion removed from database",
		"conversion.represent": "(Need French) Conversion Represent:",
		"conversion.select.destination": "(Need French) Select a destination unit",
		"conversion.select.source": "(Need French) Select a source unit",
		"conversion.slope": "(Need French) Slope:",
		"conversion.source": "(Need French) Source:",
		"conversion.submit.new.conversion": "(Need French) Submit New Conversion",
		"conversion.successfully.create.conversion": "(Need French) Successfully created a conversion.",
		"conversion.successfully.delete.conversion": "(Need French) Successfully deleted conversion.",
		"conversion.successfully.edited.conversion": "(Need French) Successfully edited conversion.",
		"create.conversion": "(Need French) Create a Conversion",
		"create.group": "Créer un Groupe",
		"create.map": "Créer une carte",
		"create.unit": "(Need French) Create a Unit",
		"create.user": "Créer un utilisateur",
		"csv": "CSV",
		"csv.file": "Fichier CSV",
		"csv.common.param.gzip": "GzipComment",
		"csv.common.param.header.row": "Ligne d'en-tête",
		"csv.common.param.update": "Mise à jour",
		"csv.download.size.limit": "(Need French) Sorry you don't have permissions to download due to large number of points.",
		"csv.download.size.warning.size": "(Need French) Total size of all files will be about (usually within 10% for large exports).",
		"csv.download.size.warning.verify": "(Need French) Are you sure you want to download",
		"csv.readings.param.create.meter": "Créer un compteur",
		"csv.readings.param.cumulative": "Cumulatif",
		"csv.readings.param.cumulative.reset": "Réinitialisation cumulative",
		"csv.readings.param.cumulative.reset.end": "Fin de la réinitialisation cumulative",
		"csv.readings.param.cumulative.reset.start": "Début de réinitialisation cumulée",
		"csv.readings.param.duplications": "Duplications",
		"csv.readings.param.endOnly": "Heures de fin uniquement",
		"csv.readings.param.honor.dst": "(Need French) Honor Daylight Savings Time",
		"csv.readings.param.lengthGap": "Écart de longueur",
		"csv.readings.param.length.variation": "Variation de longueur",
		"csv.readings.param.meter.name": "Nom du compteur",
		"csv.readings.param.refresh.hourlyReadings": "Actualiser les relevés horaires",
		"csv.readings.param.refresh.readings": "Actualiser les lectures quotidiennes",
		"csv.readings.param.relaxed.parsing": "(Need French) Relaxed Parsing",
		"csv.readings.param.time.sort": "Tri de l'heure",
		"csv.readings.section.cumulative.data": "Données cumulées",
		"csv.readings.section.time.gaps": "Intervalles de temps",
		"csv.submit.button": "Soumettre les données CSV",
		"csv.tab.meters": "Mètres",
		"csv.tab.readings": "Lectures",
		"csv.upload.meters": "Télécharger les compteurs au format CSV",
		"csv.upload.readings": "Télécharger les lectures CSV",
		"day": "Journée",
		"days": "Journées",
		"decreasing": "(need French) decreasing",
		"default.area.normalize": "(need French) Normalize readings by area by default",
		"default.area.unit": "(Need French) Default Area Unit",
		"default.bar.stacking": "(Need French) Stack bars by default",
		"default.graph.type": "Type du Diagramme par Défaut",
		"default.graph.settings": "(Need French) Default Graph Settings",
		"defaultGraphicUnit": "(Need French) Default Graphic Unit:",
		"default.language": "Langue par Défaut",
		"default.meter.reading.frequency": "(Need French) Default meter reading frequency",
		"default.site.title": "Titre du Site par Défaut",
		"default.warning.file.size": "Taille du fichier d'avertissement par défaut",
		"default.file.size.limit": "Limite de taille de fichier par défaut",
		"default.time.zone": "Zona Horaria Predeterminada",
		"delete.group": "Supprimer le Groupe",
		"delete.map": "Supprimer la carte",
		"delete.user": "Supprimer l'utilisateur",
		"descending": "Descendant",
		"discard.changes": "Annuler les Modifications",
		"disable": "Désactiver",
		"displayable": "Affichable:",
		"DisplayableType.none": "(Need French) none",
		"DisplayableType.all": "(Need French) all",
		"DisplayableType.admin": "(Need French) admin",
		"error.bounds": "(Need French) Must be between {min} and {max}.",
		"error.displayable": "(Need French) Displayable will be set to false because no unit is selected.",
		"error.displayable.meter": "(Need French) Meter units will set displayable to none.",
		"error.greater": "(Need French) Must be greater than {min}.",
		"error.gps": "(Need French) Latitude must be between -90 and 90, and Longitude must be between -180 and 180.",
		"error.negative": "(Need French) Cannot be negative.",
		"error.required": "(Need French) Required field.",
		"edit": "Modifier",
		"edited": "édité",
		"edit.a.group": "Modifier le Groupe",
		"edit.a.meter": "Modifier le Métre",
		"edit.group": "Modifier Groupe",
		"edit.meter": "Modifier Métre",
		"edit.unit": "(need French) Edit Unit",
		"email": "E-mail",
		"enable": "Activer",
		"export.graph.data": "Exporter les données du diagramme",
		"export.raw.graph.data": "(need French) Export graph meter data",
		"failed.logging.in": "Echec de la connexion",
		"failed.to.create.map": "Échec de la création d'une carte",
		"failed.to.delete.group": "N'a pas réussi à supprimer le groupe",
		"failed.to.delete.map": "Échec de la suppression d'une carte",
		"failed.to.edit.map": "Échec de la modification d'une carte",
		"failed.to.link.graph": "Échec de lier le graphique",
		"failed.to.submit.changes": "Échec de l'envoi des modifications",
		"false": "Faux",
		"gps": "(Need French) GPS: latitude, longitude",
		"graph": "Graphique",
		"graph.type": "Type du Diagramme",
		"group": "Groupe",
		"group.all.meters": "Tous les compteurs",
		"group.area.calculate": "(Need French) Calculate Group Area",
		"group.area.calculate.header": "(Need French) Group Area will be set to ",
		"group.area.calculate.error.header": "(Need French) The following meters were excluded from the sum because:",
		"group.area.calculate.error.zero": "(Need French) : area is unset or zero",
		"group.area.calculate.error.unit": "(Need French) : nonzero area but no area unit",
		"group.area.calculate.error.no.meters": "(Need French) No meters in group",
		"group.area.calculate.error.group.unit": "(Need French) No group area unit",
		"group.create.nounit": "(Need French) The default graphic unit was changed to no unit from ",
		"group.delete.group": "(Need French) Delete Group",
		"group.delete.issue": "(Need French) is contained in the following groups and cannot be deleted",
		"group.details": "(Need French) Group Details",
		"group.edit.cancelled": "(Need French) THE CHANGE TO THE GROUP IS CANCELLED",
		"group.edit.changed": "(Need French) will have its compatible units changed by the edit to this group",
		"group.edit.circular": "(Need French) Adding this meter/group to this group creates a circular dependency.",
		"group.edit.empty": "(Need French) Removing this meter/group means there are no child meters or groups which is not allowed. Delete the group if you want to remove it.",
		"group.edit.nocompatible": "(Need French) would have no compatible units by the edit to this group so the edit is cancelled",
		"group.edit.nounit": "(Need French) will have its compatible units changed and its default graphic unit set to \"no unit\" by the edit to this group",
		"group.edit.verify": "(Need French) Given the messages, do you want to cancel this change (click Cancel) or continue (click OK)?",
		"group.failed.to.create.group": "(Need French) Failed to create a group with message: ",
		"group.failed.to.edit.group": "(Need French) Failed to edit group with message: ",
		"group.gps.error": "(Need French) Please input a valid GPS: (latitude, longitude)",
		"group.hidden": "(Need French) At least one group is not visible to you",
		"group.input.error": "(Need French) Input invalid so group not created or edited.",
		"group.name.error": "(Need French) Please enter a valid name: (must have at least one character that is not a space)",
		"groups": "Groupes",
		"group.successfully.create.group": "(Need French) Successfully created a group.",
		"group.successfully.edited.group": "(Need French) Successfully edited group.",
		"groups.select": "Sélectionnez des Groupes",
		"has.no.data": "(Need French) has no current data",
		"has.used": "a utilisé",
		"header.pages": "(Need French) Pages",
		"header.options": "(Need French) Options",
		"help": "(Need French) Help",
		"help.admin.conversioncreate": "(Need French) This page allows admins to create conversions. Please visit {link} for further details and information.",
		"help.admin.conversionedit": "(Need French) This page allows admins to edit conversions. Please visit {link} for further details and information.",
		"help.admin.conversionview": "(Need French) This page shows information on conversions. Please visit {link} for further details and information.",
		"help.admin.groupcreate": "(Need French) This page allows admins to create goups. Please visit {link} for further details and information.",
		"help.admin.groupedit": "(Need French) This page allows admins to edit groups. Please visit {link} for further details and information.",
		"help.admin.groupview": "(Need French) This page allows admins to view groups. Please visit {link} for further details and information.",
		"help.admin.header": "(Need French) This page is to allow site administrators to set values and  manage users.  Please visit {link} for further details and information.",
		"help.admin.mapview": "(Need French) This page allows admins to view and edit maps. Please visit {link} for further details and information",
		"help.admin.metercreate": "(Need French) This page allows admins to create meters. Please visit {link} for further details and information.",
		"help.admin.meteredit": "(Need French) This page allows admins to edit meters. Please visit {link} for further details and information.",
		"help.admin.meterview": "(Need French) This page allows admins to view and edit meters. Please visit {link} for further details and information.",
		"help.admin.unitcreate": "(need French) This page allows admins to create units. Please visit {link} for further details and information.",
		"help.admin.unitedit": "(need French)This page allows admins to edit units. Please visit {link} for further details and information.",
		"help.admin.unitview": "(Need French) This page shows information on units. Please visit {link} for further details and information.",
		"help.admin.user": "(Need French) This page allows admins to view and edit users. Please visit {link} for further details and information.",
		"help.csv.header": "(Need French) This page allows certain users to upload meters and readings via a CSV file. Please visit {link} for further details and information.",
		"help.groups.groupdetails": "(Need French) This page shows detailed information on a group. Please visit {link} for further details and information.",
		"help.groups.groupview": "(Need French) This page shows information on groups. Please visit {link} for further details and information.",
		"help.groups.area.calculate": "(Need French) This will sum together the area of all meters in this group with a nonzero area and a set area unit. It will ignore any meters which have no area or area unit. If this group has no area unit, it will do nothing.",
		"help.home.area.normalize": "(Need French) Toggles normalization by area. Meters/Groups without area will be hidden. Please visit {link} for further details and information.",
		"help.home.bar.custom.slider.tip": "(Need French) Allows user to select the desired number of days for each bar.  Please see {link} for further details and information.",
		"help.home.bar.interval.tip": "(Need French) Selects the time interval (Day, Week or 4 Weeks) for each bar.  Please see {link} for further details and information.",
		"help.home.bar.stacking.tip": "(Need French) Bars stack on top of each other. Please see {link} for further details and information.",
		"help.home.map.interval.tip": "(Need French) Selects the time interval (the last Day, Week or 4 Weeks) for map corresponding to bar's time interval. Please see {link} for further details and information.",
		"help.home.chart.plotly.controls": "(Need French) These controls are provided by Plotly, the graphics package used by OED. You generally do not need them but they are provided in case you want that level of control. Note that some of these options may not interact nicely with OED features. See Plotly documentation at {link}.",
		"help.home.chart.redraw.restore": "(Need French) OED automatically averages data when necessary so the graphs have a reasonable number of points. If you use the controls under the graph to scroll and/or zoom, you may find the resolution at this averaged level is not what you desire. Clicking the \"Redraw\" button will have OED recalculate the averaging and bring in higher resolution for the number of points it displays. If you want to restore the graph to the full range of dates, then click the \"Restore\" button. Please visit {link} for further details and information.",
		"help.home.chart.select": "(Need French) Any graph type can be used with any combination of groups and meters. Line graphs show the usage (e.g., kW) vs. time. You can zoom and scroll with the controls right below the graph. Bar shows the total usage (e.g., kWh) for the time frame of each bar where you can control the time frame. Compare allows you to see the current usage vs. the usage in the last previous period for a day, week and four weeks. Map graphs show a spatial image of each meter where the circle size is related to four weeks of usage. Clicking on one of the choices (Line, Bar, Compare, Map) renders that graphic. Please visit {link} for further details and information.",
		"help.home.compare.interval.tip": "(Need French) Selects the time interval (Day, Week or 4 Weeks) to compare for current to previous.  Please see {link} for further details and information.",
		"help.home.compare.sort.tip": "(Need French) Allows user to select the order of multiple comparison graphs to be Alphabetical (by name), Ascending (greatest to least reduction in usage) and Descending (least to greatest reduction in usage).  Please see {link} for further details and information.",
		"help.home.export.graph.data": "(Need French) With the \"Export graph data\" button, one can export the data for the graph when viewing either a line or bar graphic. The zoom and scroll feature on the line graph allows you to control the time frame of the data exported. The \"Export graph data\" button gives the data points for the graph and not the original meter data. The \"Export graph meter data\" gives the underlying meter data (line graphs only). Please visit {link} for further details and information on when meter data export is allowed.",
		"help.home.navigation": "(Need French) The \"Graph\" button goes to the graphic page, the \"Pages\" dropdown allows navigation to information pages, the \"Options\" dropdown allows selection of language, hide options and login/out and the \"Help\" button goes to the help pages. See help on the dropdown menus or the linked pages for further information.",
		"help.home.select.groups": "(Need French) Groups aggregate (sum the usage) of any combination of groups and meters. You can choose which groups to view in your graphic from the \"Groups:\" dropdown menu. Note you can type in text to limit which groups are shown. The \"Groups\" option in the top, right \"Pages\" dropdown allows you to see more details about each group and, if you are an admin, to edit the groups. Please visit {link} for further details and information.",
		"help.home.select.maps": "(Need French) Maps are a spatial representation of a site. You can choose which map to view from the \"Maps:\" dropdown menu. Note you can type in text to limit which maps are shown. Please visit {link} for further details and information.",
		"help.home.select.meters": "(Need French) Meters are the basic unit of usage and generally represent the readings from a single usage meter. You can choose which meters to view in your graphic from the \"Meters:\" dropdown menu. Note you can type in text to limit which meters are shown. The \"Meters\" option in the top, right \"Pages\" dropdown allows you to see more details about each meter and, if you are an admin, to edit the meters. Please visit {link} for further details and information.",
		"help.home.select.rates": "(Need French) Rates determine the time normalization for a line graph. Please visit {link} for further details and information",
		"help.home.select.units": "(Need French) Units determine the values displayed in a graphic. Please visit {link} for further details and information",
		"help.home.toggle.chart.link": "(Need French) With the \"Toggle chart link\" button a box appears that gives a URL that will recreate the current graphic. The link will recreate the graphic in the future, esp. for others to see. The person using this URL will be in a fully functional OED so they can make changes after the original graphic is displayed. You can help discourage changes by choosing the option to hide the option choice so they are not visible when using the URL. Please visit {link} for further details and information.",
		"help.meters.meterview": "(Need French) This page shows information on meters. Please visit {link} for further details and information.",
		"here": "ici",
		"hide": "Cacher",
		"hide.options": "Options de quai",
		"hide.options.in.link": "(Need French) Hide options in link",
		"home": "Accueil",
		"hour": "(Need French) Hour",
		"identifier": "(Need French) Identifier:",
		"import.meter.readings": "Importer des Relevés de Mètres",
		"incompatible.groups": "(Need French) Incompatible Groups",
		"incompatible.meters": "(Need French) Incompatible Meters",
		"incompatible.units": "(Need French) Incompatible Units",
		"increasing": "(Need French) increasing",
		"info": " pour plus d'informations. ",
		"input.gps.coords.first": "(Need French) input GPS coordinate that corresponds to the point: ",
		"input.gps.coords.second": "(Need French) in this format -> latitude,longitude",
		"input.gps.range": "Coordonnée GPS invalide, la latitude doit être un nombre entier entre -90 et 90, la longitude doit être un nombre entier entre -180 et 180. (Need French)You input: ",
		"insufficient.readings": "Données de lectures insuffisantes pour la comparaison de processus pour ",
		"invalid.email.password": "Combinaison email / mot de passe invalide",
		"invalid.number": "(Need French) Please submit a valid number (between 0 and 2.0)",
		"invalid.token.login": "Le jeton a expiré. Connectez-vous à nouveau.",
		"invalid.token.login.admin": "(Need French) Le jeton a expiré. Please log in again to view this page.",
		"language": "Langue",
		"last.four.weeks": "Quatre dernières semaines",
		"last.week": "La semaine dernière",
		"leave": "(Need French) Leave",
		"less.energy": "moins d'énergie",
		"line": "Ligne",
		"log.in": "Se Connecter",
		"log.out": "Se Déconnecter",
		"logo": "Logo",
		"manage": "(Need French) Manage",
		"map": "Carte",
		"maps": "Plans",
		"map.bad.number": "Pas un nombre, veuillez changer l'angle en un nombre entre 0 et 360",
		"map.bad.digita": "Supérieur à 360, veuillez changer l'angle en un nombre compris entre 0 et 360",
		"map.bad.digitb": "Moins de 0, veuillez changer l'angle en un nombre compris entre 0 et 360",
		"map.calibrate": "Étalonner",
		"map.calibration": "Statut d'étalonnage",
		"map.circle.size": "Taille du cercle de la carte",
		"map.confirm.remove": "Voulez-vous vraiment supprimer la carte?",
		"map.displayable": "Affichage de la carte",
		"map.filename": "Fichier de carte",
		"map.id": "ID de la carte",
		"map.interval": "Intervalle de carte",
		"map.is.calibrated": "Étalonnage terminé",
		"map.is.deleted": "Carte supprimée de la base de données",
		"map.is.displayable": "Affichage activé",
		"map.is.not.calibrated": "Étalonnage nécessaire",
		"map.is.not.displayable": "Affichage désactivé",
		"map.load.complete": "Chargement de la carte terminé à partir de",
		"map.modified.date": "Dernière modification",
		"map.name": "Nom de la carte",
		"map.new.angle": "Indiquez l'angle par rapport au vrai nord (0 à 360)",
		"map.new.name": "Définir un nom pour la carte",
		"map.new.submit": "Sauvegarder et continuer",
		"map.new.upload": "Téléchargez l'image de la carte pour commencer.",
		"map.notify.calibration.needed": "Étalonnage nécessaire pour l'affichage",
		"map.upload.new.file": "Refaire",
		"menu": "Menu",
		"meter": "Mèter",
		"meters": "Mèters",
		"meter.create": "(Need French) Create a Meter",
		"meter.cumulative": "(Need French) Cumulative:",
		"meter.cumulativeReset": "(Need French) Cumulative Reset:",
		"meter.cumulativeResetEnd": "(Need French) Cumulative Reset End:",
		"meter.cumulativeResetStart": "(Need French) Cumulative Reset Start:",
		"meter.enabled": "Mises à Jour du Mèters",
		"meter.endOnlyTime": "(Need French) End Only Time:",
		"meter.endTimeStamp": "(Need French) End Time Stamp:",
		"meter.failed.to.create.meter": "(Need French) Failed to create a meter with message: ",
		"meter.failed.to.edit.meter": "(Need French) Failed to edit meter with message: ",
		"meter.hidden": "(Need French) At least one meter is not visible to you",
		"meter.id": "Identifiant du Mèters",
		"meter.input.error": "(Need French) Input invalid so meter not created or edited.",
		"meter.unit.change.requires": "(Need French) needs to be changed before changing this unit's type",
		"meter.unitName": "(Need French) Unit:",
		"meter.url": "URL du Mèters",
		"meter.is.displayable": "Affichage Activées",
		"meter.is.enabled": "Mises à Jour Activées",
		"meter.is.not.displayable": "Affichage Désactivé",
		"meter.is.not.enabled": "Mises à Jour Désactivées",
		"meter.previousEnd": "(need French) Previous End Time Stamp:",
		"meter.readingDuplication": "(need French) Reading Duplication:",
		"meter.readingFrequency": "(need French) Reading Frequency",
		"meter.readingGap": "(need French) Reading Gap:",
		"meter.readingVariation": "(need French) Reading Variation:",
		"meter.startTimeStamp": "(need French) Start Time Stamp:",
		"meter.successfully.create.meter": "(Need French) Successfully created a meter.",
		"meter.successfully.edited.meter": "(Need French) Successfully edited meter.",
		"meter.timeSort": "(need French) Time Sort:",
		"meter.time.zone": "fuseau horaire du mètre",
		"meter.type": "Type de Mèters",
		"minute": "(need French) Minute",
		"more.energy": "plus d'énergie",
		"name": "Nom:",
		"navigation": "Navigation",
		"need.more.points": "(Need French) Need more points",
		"no": "(need French) no",
		"note": "Noter:",
		"oed": "Tableau de Bord Ouvert d'énergie",
		"oed.description": "Le Tableau de Bord Ouvert d'énergie est un projet open source indépendant. ",
		"oed.version": "(Need French) OED version ",
		"options": "Options",
		"page.choice.login": "(Need French) Page choices & login",
		"page.choice.logout": "(Need French) Page choices & logout",
		"password": "Mot de passe",
		"password.confirm": "(Need French) Confirm password",
		"per.day": "(Need French) Per Day",
		"per.hour": "(Need French) Per Hour",
		"per.minute": "(Need French) Per Minute",
		"per.second": "(Need French) Per Second",
		"projected.to.be.used": "projeté pour être utilisé",
		"rate": "(need French) Rate",
		"reading": "(need French) Reading:",
		"redo.cik.and.refresh.db.views": "(need French) Processing changes. This may take a while",
		"redraw": "Redessiner",
		"remove": "(need French) Remove",
		"restore": "Restaurer",
		"role": "(Need French) Role",
		"save.all": "(Need French) Save all",
		"save.map.edits": "(Need French) Save map edits",
		"save.meter.edits": "Enregistrer les modifications de compteur",
		"save.role.changes": "(Need French) Save role changes",
		"second": "(Need French) Second",
		"select.groups": "Sélectionnez des Groupes",
		"select.map": "(Need French) Select Map",
		"select.meter.type": "(Need French) Select Meter Type",
		"select.meter": "Sélectionnez de Mètres",
		"select.meter.group": "(Need French) Select meter or group to graph",
		"select.meters": "Sélectionnez des Mètres",
		"select.unit": "(Need French) Select Unit",
		"show": "Montrer",
		"show.grid": "(Need French) Show grid",
		"show.options": "Options de désancrage",
		"sort": "Trier",
		"submit": "Soumettre",
		"submitting": "(Need French) submitting",
		"submit.changes": "Soumettre les changements",
		"submit.new.user": "(Need French) Submit new user",
		"the.unit.of.meter": "(Need French) The unit of meter",
		"this.four.weeks": "Cette quatre semaines",
		"this.week": "Cette semaine",
		"timezone.no": "Pas de fuseau horaire",
		"TimeSortTypes.decreasing": "décroissant",
		"TimeSortTypes.increasing": "en augmentant",
		"TimeSortTypes.meter": "valeur du compteur ou valeur par défaut",
		"today": "Aujourd'hui",
		"toggle.custom.slider": "Basculer le curseur personnalisé",
		"toggle.link": "Bascule du lien du diagramme",
		"total": "total",
		"true": "Vrai",
		"TrueFalseType.false": "(Need French) no",
		"TrueFalseType.true": "(Need French) yes",
		"undefined": "(Need French) undefined",
		"unit": "(Need French) Unit",
		"UnitRepresentType.quantity": "(Need French) quantity",
		"UnitRepresentType.flow": "(Need French) flow",
		"UnitRepresentType.raw": "(Need French) raw",
		"UnitType.unit": "(Need French) unit",
		"UnitType.meter": "(Need French) meter",
		"UnitType.suffix": "(Need French) suffix",
		"unit.dropdown.displayable.option.none": "(Need French) None",
		"unit.dropdown.displayable.option.all": "(Need French) All",
		"unit.dropdown.displayable.option.admin": "(Need French) admin",
		"unit.failed.to.create.unit": "(Need French) Failed to create a unit.",
		"unit.failed.to.edit.unit": "(Need French) Failed to edit unit.",
		"unit.input.error": "(Need French) Input invalid so unit not created or edited.",
		"unit.none": "(Need French) no unit",
		"unit.preferred.display": "(Need French) Preferred Display:",
		"unit.represent": "(Need French) Unit Represent:",
		"unit.sec.in.rate": "(Need French) Sec in Rate:",
		"unit.submit.new.unit": "(Need French) Submit New Unit",
		"unit.successfully.create.unit": "(Need French) Successfully created a unit.",
		"unit.successfully.edited.unit": "(Need French) Successfully edited unit.",
		"unit.suffix": "(Need French) Suffix:",
		"unit.type.of.unit": "(Need French) Type of Unit:",
		"units": "(Need French) Units",
		"unsaved.warning": "(Need French) You have unsaved change(s). Are you sure you want to leave?",
		"update": "update",
		"updated.map.with.calibration": "(need French) Updated map with renewed calibration",
		"updated.map.without.calibration": "(need French) Updated map(uncalibrated)",
		"updated.map.without.new.calibration": "(need French) Updated map",
		"updated.preferences": "Préférences mises à jour",
		"upload.meters.csv": "Télécharger le fichier CSV mètres",
		"upload.new.map.with.calibration": "(need French) Uploaded new calibrated map",
		"upload.new.map.without.calibration": "(need French) Uploaded new map(uncalibrated)",
		"upload.readings.csv": "Télécharger les lectures fichier CSV",
		"used.so.far": "utilisé jusqu'à présent",
		"used.this.time": "utilisé cette fois",
		"users": "(Need French) Users",
		"users.failed.to.create.user": "(Need French) Failed to create a user.",
		"users.failed.to.delete.user": "(Need French) Failed to delete a user.",
		"users.failed.to.edit.users": "(Need French) Failed to edit users.",
		"users.successfully.create.user": "(Need French) Successfully created a user.",
		"users.successfully.delete.user": "(Need French) Successfully deleted user.",
		"users.successfully.edit.users": "(Need French) Successfully edited users.",
		"view.groups": "Visionner les groupes",
		"visit": " ou visitez notre ",
		"website": "site web",
		"week": "Semaine",
		"yes": "(need French) yes",
		"yesterday": "Hier",
		"you.cannot.create.a.cyclic.group": "Vous ne pouvez pas créer un groupe cyclique"
	},
	"es": {
		"400": "(Need Spanish) 400 Bad Request",
		"404": "404 Página no encontrada",
		"4.weeks": "4 Semanas",
		"action": "Acción",
		"add.new.meters": "Agregar nuevos medidores",
		"admin.only": "Solo administrador",
		"admin.panel": "Panel de administración",
		"alphabetically": "Alfabéticamente",
		"area": "Área:",
		"area.but.no.unit": "(Need Spanish)You have entered a nonzero area but no area unit.",
		"area.error": "Por favor entra un numero por la área",
		"area.normalize": "(Need Spanish) Normalize by Area",
		"area.calculate.auto": "(Need Spanish) Calculate Group Area",
		"area.unit": "(Need Spanish) Area Unit:",
		"AreaUnitType.feet": "pies cuadrados",
		"AreaUnitType.meters": "metros cuadrados",
		"AreaUnitType.none": "(Need Spanish) no unit",
		"ascending": "Ascendente",
		"bar": "Barra",
		"bar.interval": "Intervalo de barra",
		"bar.raw": "(Need Spanish) Cannot create bar graph on raw units such as temperature",
		"bar.stacking": "Barra de apilamiento",
		"BooleanMeterTypes.false": "sí",
		"BooleanMeterTypes.meter": "(Need Spanish) meter or default value",
		"BooleanMeterTypes.true": "no",
		"calibration.display": "Resultado: ",
		"calibration.reset.button": "Restablecimiento",
		"calibration.save.database": "Guardar los cambios en la base de datos",
		"calibration.submit.button": "Enviar",
		"cancel": "Cancelar",
		"child.groups": "Los grupos secundarios",
		"child.meters": "Medidores infantiles",
		"close": "(need Spanish) Close",
		"compare": "Comparar",
		"compare.raw": "(Need Spanish) Cannot create comparison graph on raw units such as temperature",
		"confirm.action": "(Need Spanish) Confirm Action",
		"contact.us": "Contactenos",
		"conversion": "(Need Spanish) Conversion",
		"conversions": "(Need Spanish) Conversions",
		"ConversionType.conversion": "(Need Spanish) conversion",
		"conversion.bidirectional": "(Need Spanish) Bidirectional:",
		"conversion.create.destination.meter": "(Need Spanish) The destination cannot be a meter",
		"conversion.create.exists": "(Need Spanish) This conversion already exists",
		"conversion.create.exists.inverse": "(Need Spanish) This conversion already exists where one is not bidirectional",
		"conversion.create.mixed.represent": "(Need Spanish) A conversion cannot mix units of quantity, flow and raw",
		"conversion.create.source.destination.same": "(Need Spanish) The source and destination cannot be the same for a conversion",
		"conversion.delete.conversion": "(Need Spanish) Delete Conversion",
		"conversion.destination": "(Need Spanish) Destination:",
		"conversion.dropdown.displayable.option.admin": "(Need Spanish) Admin",
		"conversion.edit.conversion": "(need Spanish) Edit Conversion",
		"conversion.failed.to.create.conversion": "(Need Spanish) Failed to create a conversion.",
		"conversion.failed.to.delete.conversion": "(Need Spanish) Failed to delete conversion",
		"conversion.failed.to.edit.conversion": "(Need Spanish) Failed to edit conversion.",
		"conversion.intercept": "(Need Spanish) Intercept:",
		"conversion.is.deleted": "(Need Spanish) Conversion removed from database",
		"conversion.represent": "(Need Spanish) Conversion Represent:",
		"conversion.select.destination": "(Need Spanish) Select a destination unit",
		"conversion.select.source": "(Need Spanish) Select a source unit",
		"conversion.slope": "(Need Spanish) Slope:",
		"conversion.source": "(Need Spanish) Source:",
		"conversion.submit.new.conversion": "(Need Spanish) Submit New Conversion",
		"conversion.successfully.create.conversion": "(Need Spanish) Successfully created a conversion.",
		"conversion.successfully.delete.conversion": "(Need Spanish) Successfully deleted conversion.",
		"conversion.successfully.edited.conversion": "(Need Spanish) Successfully edited conversion.",
		"create.conversion": "(Need Spanish) Create a Conversion",
		"create.group": "Crear un grupo",
		"create.map": "Crear una mapa",
		"create.unit": "(Need Spanish) Create a Unit",
		"create.user": "Crear un usario",
		"csv": "CSV",
		"csv.file": "Archivo de CSV",
		"csv.common.param.gzip": "Gzip",
		"csv.common.param.header.row": "Fila de cabecera",
		"csv.common.param.update": "Actualización",
		"csv.download.size.limit": "(need Spanish) Sorry you don't have permissions to download due to large number of points.",
		"csv.download.size.warning.size": "(Need Spanish) Total size of all files will be about (usually within 10% for large exports).",
		"csv.download.size.warning.verify": "(Need Spanish) Are you sure you want to download",
		"csv.readings.param.create.meter": "Crear medidor",
		"csv.readings.param.cumulative": "Acumulado",
		"csv.readings.param.cumulative.reset": "Reinicio acumulativo",
		"csv.readings.param.cumulative.reset.end": "Fin de reinicio acumulativo",
		"csv.readings.param.cumulative.reset.start": "Iniciar de reinicio acumulativo",
		"csv.readings.param.duplications": "Duplicaciones",
		"csv.readings.param.endOnly": "Fin solamente de tiempo",
		"csv.readings.param.honor.dst": "(Need Spanish) Honor Daylight Savings Time",
		"csv.readings.param.lengthGap": "Espacio de largo",
		"csv.readings.param.length.variation": "Distancia de variación",
		"csv.readings.param.meter.name": "Nombre de medidor",
		"csv.readings.param.refresh.hourlyReadings": "Actualizar lecturas por hora",
		"csv.readings.param.refresh.readings": "Actualizar lecturas diarias",
		"csv.readings.param.relaxed.parsing": "(Need Spanish) Relaxed Parsing",
		"csv.readings.param.time.sort": "Ordenar el tiempo",
		"csv.readings.section.cumulative.data": "Datos acumulativos",
		"csv.readings.section.time.gaps": "Espacio de tiempo",
		"csv.submit.button": "Enviar datos de CSV",
		"csv.tab.meters": "Medidor",
		"csv.tab.readings": "Lecturas",
		"csv.upload.meters": "Cargar medidores de CSV",
		"csv.upload.readings": "Cargar lecturas de CSV",
		"day": "Día",
		"days": "Días",
		"decreasing": "(need Spanish) decreasing",
		"default.area.normalize": "(need Spanish) Normalize readings by area by default",
		"default.area.unit": "(Need Spanish) Default Area Unit",
		"default.bar.stacking": "(Need Spanish) Stack bars by default",
		"default.graph.type": "Por defecto tipo de gráfico",
		"default.graph.settings": "(Need Spanish) Default Graph Settings",
		"defaultGraphicUnit": "(Need Spanish) Default Graphic Unit:",
		"default.language": "Idioma por defecto",
		"default.meter.reading.frequency": "(Need Spanish) Default meter reading frequency",
		"default.site.title": "Título del Sitio Predeterminado",
		"default.warning.file.size": "Tamaño de Archivo por Defecto de Advertencia",
		"default.file.size.limit": "El límite de Archivo por Defecto de Tamaño",
		"default.time.zone": "Fuseau Horaire par Défaut",
		"delete.group": "Eliminar grupo",
		"delete.map": "Borrar mapa",
		"delete.user": "Borrar usario",
		"descending": "Descendente",
		"discard.changes": "Descartar los Cambios",
		"disable": "Desactivar",
		"displayable": "Visualizable:",
		"DisplayableType.none": "(Need Spanish) none",
		"DisplayableType.all": "(Need Spanish) all",
		"DisplayableType.admin": "(Need Spanish) admin",
		"error.bounds": "(Need Spanish) Must be between {min} and {max}.",
		"error.displayable": "(Need Spanish) Displayable will be set to false because no unit is selected.",
		"error.displayable.meter": "(Need Spanish) Meter units will set displayable to none.",
		"error.greater": "(Need Spanish) Must be greater than {min}.",
		"error.gps": "(Need Spanish) Latitude must be between -90 and 90, and Longitude must be between -180 and 180.",
		"error.negative": "(Need Spanish) Cannot be negative.",
		"error.required": "(Need Spanish) Required field.",
		"edit": "Editar",
		"edited": "Editado",
		"edit.a.group": "Editar un grupo",
		"edit.a.meter": "Editar un medidor",
		"edit.group": "Edición de grupo",
		"edit.meter": "Editar Medidor",
		"edit.unit": "(need Spanish) Edit Unit",
		"email": "Correo Electronico",
		"enable": "Activar",
		"export.graph.data": "Exportar datos del gráfico",
		"export.raw.graph.data": "Exportar datos gráficos de medidor",
		"failed.logging.in": "Error al iniciar sesión",
		"failed.to.create.map": "No se pudo crear el mapa",
		"failed.to.delete.group": "No se pudo eliminar el grupo",
		"failed.to.delete.map": "No se pudo borrar el mapa",
		"failed.to.edit.map": "No se pudo editar el mapa",
		"failed.to.link.graph": "No se pudo vincular el gráfico",
		"failed.to.submit.changes": "No se pudo entregar los cambios",
		"false": "Falso",
		"gps": "GPS: latitud, longitud",
		"graph": "Gráfico",
		"graph.type": "Tipo de gráfico",
		"group": "Grupo",
		"group.all.meters": "(Need Spanish) All Meters",
		"group.area.calculate": "(Need Spanish) Calculate Group Area",
		"group.area.calculate.header": "(Need Spanish) Group Area will be set to ",
		"group.area.calculate.error.header": "(Need Spanish) The following meters were excluded from the sum because:",
		"group.area.calculate.error.zero": "(Need Spanish) : area is unset or zero",
		"group.area.calculate.error.unit": "(Need Spanish) : nonzero area but no area unit",
		"group.area.calculate.error.no.meters": "(Need Spanish) No meters in group",
		"group.area.calculate.error.group.unit": "(Need Spanish) No group area unit",
		"group.create.nounit": "(Need Spanish) The default graphic unit was changed to no unit from ",
		"group.delete.group": "(Need Spanish) Delete Group",
		"group.delete.issue": "(Need Spanish) is contained in the following groups and cannot be deleted",
		"group.details": "(Need Spanish) Group Details",
		"group.edit.cancelled": "(Need Spanish) THE CHANGE TO THE GROUP IS CANCELLED",
		"group.edit.changed": "(Need Spanish) will have its compatible units changed by the edit to this group",
		"group.edit.circular": "(Need Spanish) Adding this meter/group to this group creates a circular dependency.",
		"group.edit.empty": "(Need Spanish) Removing this meter/group means there are no child meters or groups which is not allowed. Delete the group if you want to remove it.",
		"group.edit.nocompatible": "(Need Spanish) would have no compatible units by the edit to this group so the edit is cancelled",
		"group.edit.nounit": "(Need Spanish) will have its compatible units changed and its default graphic unit set to \"no unit\" by the edit to this group",
		"group.edit.verify": "(Need Spanish) Given the messages, do you want to cancel this change (click Cancel) or continue (click OK)?",
		"group.failed.to.create.group": "(Need Spanish) Failed to create a group with message: ",
		"group.failed.to.edit.group": "(Need Spanish) Failed to edit group with message: ",
		"group.gps.error": "Por favor importa un GPS valído: (latitud, longitud)",
		"group.hidden": "(Need Spanish) At least one group is not visible to you",
		"group.input.error": "(Need Spanish) Input invalid so group not created or edited.",
		"group.name.error": "(Need Spanish) Please enter a valid name: (must have at least one character that is not a space)",
		"groups": "Grupos",
		"group.successfully.create.group": "(Need Spanish) Successfully created a group.",
		"group.successfully.edited.group": "(Need Spanish) Successfully edited group.",
		"groups.select": "Seleccionar grupos",
		"has.no.data": "(Need Spanish) has no current data",
		"has.used": "ha utilizado",
		"header.pages": "(Need Spanish) Pages",
		"header.options": "Opciones",
		"help": "(Need Spanish) Help",
		"help.admin.conversioncreate": "(Need Spanish) This page allows admins to create conversions. Please visit {link} for further details and information.",
		"help.admin.conversionedit": "(Need Spanish) This page allows admins to edit conversions. Please visit {link} for further details and information.",
		"help.admin.conversionview": "(Need Spanish) This page shows information on conversions. Please visit {link} for further details and information.",
		"help.admin.groupcreate": "(Need Spanish) This page allows admins to create goups. Please visit {link} for further details and information.",
		"help.admin.groupedit": "(Need Spanish) This page allows admins to edit groups. Please visit {link} for further details and information.",
		"help.admin.groupview": "Esta página permite a los administradores ver grupos. Por favor, visite {link} para más detalles e información.",
		"help.admin.header": "Esta página permite a los administradores del sitio establecer valores y gestionar usarios.  Por favor, visite {link} para más detalles e información.",
		"help.admin.mapview": "Esta página permite a los administradores ver e editar mapas. Por favor, visite {link} para más detalles e información",
		"help.admin.metercreate": "(Need Spanish) This page allows admins to create meters. Please visit {link} for further details and information.",
		"help.admin.meteredit": "(Need Spanish) This page allows admins to edit meters. Please visit {link} for further details and information.",
		"help.admin.meterview": "Esta página permite a los administradores ver e editar los mediores. Por favor, visite {link} para más detalles e información.",
		"help.admin.unitcreate": "(need Spanish) This page allows admins to create units. Please visit {link} for further details and information.",
		"help.admin.unitedit": "(need Spanish) This page allows admins to edit units. Please visit {link} for further details and information.",
		"help.admin.unitview": "(Need Spanish) This page shows information on units. Please visit {link} for further details and information.",
		"help.admin.user": "Esta página permite a los administradores ver e editar los usarios. Por favor, visite {link} para más detalles e información.",
		"help.csv.header": "Esta página permite unos usuarios cargar medidores y lecturas a través de un archivo CSV. Por favor, visite {link} para más detalles e información.",
		"help.groups.groupdetails": "(Need Spanish) This page shows detailed information on a group. Please visit {link} for further details and information.",
		"help.groups.groupview": "Esta página muestra información sobre los grupos. Por favor, visite {link} para más detalles e información.",
		"help.groups.area.calculate": "(Need Spanish) This will sum together the area of all meters in this group with a nonzero area and a set area unit. It will ignore any meters which have no area or area unit. If this group has no area unit, it will do nothing.",
		"help.home.area.normalize": "(Need Spanish) Toggles normalization by area. Meters/Groups without area will be hidden. Please visit {link} for further details and information.",
		"help.home.bar.custom.slider.tip": "Permite al usuario seleccionar el número de días deseado para cada barra.  Por favor, vea {link} para más detalles e información.",
		"help.home.bar.interval.tip": "Selecciona el intervalo de tiempo (Día, Semana o Cuatro semanas) para cada barra.  Por favor, vea {link} para más detalles e información.",
		"help.home.bar.stacking.tip": "Pila barras en la parte superior de uno al otro. Visite {link} para obtener más detalles e información.",
		"help.home.map.interval.tip": "Selecionar el intervalo de tiempo (el último día, semana o cuatro semanas) para el correspondiente de mapa al intervalo de tiempo de la barra. Por favor ver {link} para mas detalles e información",
		"help.home.chart.plotly.controls": "Estos controles son proporcionados por Plotly, el paquete de gráficos utilizado por OED. Por lo general no se necesita pero se proporcionan por si se deséa ese nivel de control. Tenga en cuenta que quizás algunas de estas opciones no interactúen bien con las funciones de OED. Consulte la documentación de Plotly en {link}.",
		"help.home.chart.redraw.restore": "OED  automáticamente promedia los datos cuando sea necesario para que los gráficos tengan un número razonable de puntos. Si usa los controles debajo del gráfico para desplazarse y / o acercarse, puede encontrar que la resolución en este promedio nivel no es la que desea. Al hacer clic en el botón \"Redraw\" OED volverá a calcular el promedio y obtendrá una resolución más alta para el número de puntos que muestra. Si desea restaurar el gráfico al rango completo de fechas, haga clic en el botón \"Restore\" button. Visite {link} para obtener más detalles e información.",
		"help.home.chart.select": "Se puede usar cualquier tipo de gráfico con cualquier combinación de grupos y medidores. Los gráficos de líneas muestran el uso (por ejemplo, kW) contra el tiempo. Puede hacer zoom y desplazarse con los controles justo debajo del gráfico. La barra muestra el uso total (por ejemplo, kWh) para el período de tiempo de cada barra donde puede controlar el período de tiempo. Comparar le permite ver el uso actual contra el uso de el último período anterior durante un día, una semana y cuatro semanas.  Gráficos del mapa muestra un imagen especial de cada meter donde el talla del círculo está relacionado de cuatro semanas de uso. Al hacer clic en uno de las opciones (Línea, Barra, Comparar, Mapa) va a representa ese gráfico. Visite {link} para obtener más detalles e información.",
		"help.home.compare.interval.tip": "Selecciona el intervalo de tiempo (Día, Semana o Cuatro semanas) para comparar el actual con el anterior.  Por favor, vea {link} para más detalles e información.",
		"help.home.compare.sort.tip": "Permite al usuario seleccionar el orden de los gráficos de comparación múltiple para que sean alfabéticos (por nombre), ascendentes (de mayor a menor reducción de uso) y descendentes (de menor a mayor reducción de uso). Por favor, vea {link} para más detalles e información.",
		"help.home.export.graph.data": "Con el botón \"Exportar data de gráfico\", uno puede exportar los datos del gráfico al ver una línea o barra el gráfico. La función de zoom y desplazamiento en el gráfico de líneas le permite controlar el período de tiempo de los datos exportados.  El \"Exportar data de gráfico\" botón da el puntos de dato para el gráfico y no los datos sin procesar de medidor. La \"Exportar el dato gráfhico de medidor\" proporciona los datos del medidor subyacente (solo gráficos de líneas). Visite {link} para obtener más detalles e información.",
		"help.home.navigation": "(Need Spanish) The \"Graph\" button goes to the graphic page, the \"Pages\" dropdown allows navigation to information pages, the \"Options\" dropdown allows selection of language, hide options and login/out and the \"Help\" button goes to the help pages. See help on the dropdown menus or the linked pages for further information.",
		"help.home.select.groups": "Los grupos se agregan (suman el uso) de cualquier combinación de grupos y medidores. Puede elegir qué grupos ver en su gráfico desde el menú desplegable \"Groups:\" . Tenga en cuenta que puede escribir texto para limitar que grupos se muestran. La opción \"Grupos\" en el menú desplegable \"Páginas\" en la parte superior derecha de la ventana le permite ver más detalles sobre cada grupo y, si es un administrador, editar los grupos. Visite {link} para obtener más detalles e información.",
		"help.home.select.maps": "Los mapas son una representación espacial de un sitio. Puede elegir el mapa que desea ver en el menú desplegable \"Mapas:\". Tenga en cuenta que puede introducir texto para limitar las mapas que se muestran. Por favor, visite {link} para más detalles e información.",
		"help.home.select.meters": "Medidor son la unidad básica de uso y generalmente representan las lecturas de un solo medidor de uso. Puede elegir qué medidores ver en su gráfico desde el menú desplegable \"Meters:\". Tenga en cuenta que puede escribir texto para limitar los medidores que se muestran. La opción \"Medidores\" en el menú desplegable \"Páginas\" en la parte superior derecha de la ventana le permite ver más detalles sobre cada medidor y, si es un administrador, editar los medidores . Visite {link} para obtener más detalles e información.",
		"help.home.select.rates": "(Need Spanish) Rates determine the time normalization for a line graph. Please visit {link} for further details and information",
		"help.home.select.units": "(Need Spanish) Units determine the values displayed in a graphic. Please visit {link} for further details and information",
		"help.home.toggle.chart.link": "Con el botón \"Alternar enlace de gráfico\" aparece un cuadro que da un URL que recreará el gráfico actual. El vínculo recreará el gráfico en el futuro, especialmente para que otros lo vean. La persona que usa este URL estará en un OED completamente funcional para que pueda realizar cambios después de que se muestre el gráfico original. Puede ayudar a desalentar los cambios eligiendo la opción para acoplar las opciones del menú para que no sean visible al usar el URL. Visite {link} para obtener más detalles e información.",
		"help.meters.meterview": "Esta página muestra información sobre los medidores.  Visite {link} para obtener más detalles e información.",
		"here": "aquí",
		"hide": "Ocultar",
		"hide.options": "Opciones de muelle",
		"hide.options.in.link": "Ocultar las opciones en el enlace",
		"home": "Hogar",
		"identifier": "(Need Spanish) Identifier:",
		"hour": "Hora",
		"import.meter.readings": "Importar lecturas del medidor",
		"incompatible.groups": "(Need Spanish) Incompatible Groups",
		"incompatible.meters": "(Need Spanish) Incompatible Meters",
		"incompatible.units": "(Need Spanish) Incompatible Units",
		"increasing": "(need Spanish) increasing",
		"info": " para obtener más información. ",
		"input.gps.coords.first": "Entrada el cordenata de GPS que corresponde al punto: ",
		"input.gps.coords.second": "En este forma -> latitud, longitud",
		"input.gps.range": "Coordenada GPS no válida, la latitud debe ser un número entero entre -90 y 90, la longitud debe ser un número entero entre -180 y 180. (need Spanish). You input: ",
		"insufficient.readings": "Datos de lecturas insuficientes para procesar la comparación de",
		"invalid.email.password": "Correo electrónico o contraseña no válidos",
		"invalid.number": "Por favor entregar un número válido (entre 0 a 2.0)",
		"invalid.token.login": "El token ha expirado. Inicie sesión nuevamente",
		"invalid.token.login.admin": "(Need Spanish) El token ha expirado. Inicie sesión nuevamente to view this page.",
		"language": "Idioma",
		"last.four.weeks": "Últimas cuatro semanas",
		"last.week": "La semana pasada",
		"leave": "Salir",
		"less.energy": "menos energía",
		"line": "Línea",
		"log.in": "Iniciar sesión",
		"log.out": "Cerrar sesión",
		"logo": "Logo",
		"manage": "Gestionar",
		"map": "Mapa",
		"maps": "Mapas",
		"map.bad.number": "Ni un número, por favor cambiar el angúlo entre 0 a 360",
		"map.bad.digita": "Mayor que 360, por favor cambiar el angúlo a un número entre 0 a 360",
		"map.bad.digitb": "Menor que 0, por favor cambiar el angúlo a un número entre 0 a 360",
		"map.calibrate": "Calibrar",
		"map.calibration": "Estado de calibración",
		"map.circle.size": "El Circulo Tamaño de el Mapa",
		"map.confirm.remove": "¿Estás seguro de que quieres eliminar el mapa",
		"map.displayable": "Visuilización de el mapa",
		"map.filename": "Archivo de mapa",
		"map.id": "Mapa ID",
		"map.interval": "Intervalo de mapa",
		"map.is.calibrated": "Calibración completa",
		"map.is.deleted": "Mapa eliminado de la base de datos",
		"map.is.displayable": "Pantalla activado",
		"map.is.not.calibrated": "Necesitar calibración",
		"map.is.not.displayable": "Pantalla desactivada",
		"map.load.complete": "Carga de mapas completa de",
		"map.modified.date": "Ultima modificación",
		"map.name": "Nombre de mapa",
		"map.new.angle": " Enumere el ángulo con respecto al norte verdadero (0 a 360)",
		"map.new.name": "Defina un nombre para el mapa:",
		"map.new.submit": "Guardar y continuar",
		"map.new.upload": "Subir la imagen del mapa para empezar.",
		"map.notify.calibration.needed": "Nececitar calibración antes de visualizar",
		"map.upload.new.file": "Rehacer",
		"menu": "Menú",
		"meter": "Medidor",
		"meters": "Medidores",
		"meter.create": "(need Spanish) Create a Meter",
		"meter.cumulative": "(need Spanish) Cumulative:",
		"meter.cumulativeReset": "(need Spanish) Cumulative Reset:",
		"meter.cumulativeResetEnd": "(need Spanish) Cumulative Reset End:",
		"meter.cumulativeResetStart": "(need Spanish) Cumulative Reset Start:",
		"meter.enabled": "Medidor activada",
		"meter.endOnlyTime": "(need Spanish) End Only Time:",
		"meter.endTimeStamp": "(need Spanish) End Time Stamp:",
		"meter.failed.to.create.meter": "(Need Spanish) Failed to create a meter with message: ",
		"meter.failed.to.edit.meter": "(Need Spanish) Failed to edit meter with message: ",
		"meter.hidden": "(Need Spanish) At least one meter is not visible to you",
		"meter.id": "ID del medidor",
		"meter.input.error": "(Need Spanish) Input invalid so meter not created or edited.",
		"meter.unit.change.requires": "(Need Spanish) needs to be changed before changing this unit's type",
		"meter.unitName": "(need Spanish) Unit:",
		"meter.url": "URL del medidor",
		"meter.is.displayable": "El medidor es visualizable",
		"meter.is.enabled": "Actualizaciones Habilitado",
		"meter.is.not.displayable": "El medidor es visualizable",
		"meter.is.not.enabled": "El medidor no esta activada",
		"meter.previousEnd": "(need Spanish) Previous End Time Stamp:",
		"meter.readingDuplication": "(need Spanish) Reading Duplication:",
		"meter.readingFrequency": "(need Spanish) Reading Frequency",
		"meter.readingGap": "(need Spanish) Reading Gap:",
		"meter.readingVariation": "(need Spanish) Reading Variation:",
		"meter.startTimeStamp": "(need Spanish) Start Time Stamp:",
		"meter.successfully.create.meter": "(Need Spanish) Successfully created a meter.",
		"meter.successfully.edited.meter": "(Need Spanish) Successfully edited meter.",
		"meter.timeSort": "(need Spanish) Time Sort:",
		"meter.time.zone": "Zona horaria del medidor",
		"meter.type": "Tipo de medidor",
		"minute": "Minuto",
		"more.energy": "más energía",
		"name": "Nombre:",
		"navigation": "Navegación",
		"need.more.points": "Nececitar más puntos",
		"no": "no",
		"note": "Nota:",
		"oed": "Panel de Energía Abierta",
		"oed.description": "Open Energy Dashboard es un proyecto independiente. ",
		"oed.version": "OED versión ",
		"options": "Opciones",
		"page.choice.login": "(Need Spanish) Page choices & login",
		"page.choice.logout": "(Need Spanish) Page choices & logout",
		"password": "Contraseña",
		"password.confirm": "Confirmar contraseña",
		"per.day": "(need Spanish) Per Day",
		"per.hour": "(need Spanish) Per Hour",
		"per.minute": "(need Spanish) Per Minute",
		"per.second": "(need Spanish) Per Second",
		"projected.to.be.used": "proyectado para ser utilizado",
		"rate": "(need Spanish) Rate",
		"reading": "(need Spanish) Reading:",
		"redo.cik.and.refresh.db.views": "(need Spanish) Processing changes. This may take a while.",
		"redraw": "Redibujar",
		"remove": "Eliminar",
		"restore": "Restaurar",
		"role": "Rol",
		"save.all": "Guardar todos",
		"save.map.edits": "Guardar las ediciones del mapa",
		"save.meter.edits": "Guardar ediciones del medidor",
		"save.role.changes": "Guardar cambios de rol",
		"second": "Segundo",
		"select.groups": "Seleccionar grupos",
		"select.map": "Seleccionar mapa",
		"select.meter.type": "(Need Spanish) Select Meter Type",
		"select.meter": "Seleccionar medidor",
		"select.meter.group": "(Need Spanish) Select meter or group to graph",
		"select.meters": "Seleccionar medidores",
		"select.unit": "(Need Spanish) Select Unit",
		"show": "Mostrar",
		"show.grid": "Mostrar rejilla",
		"show.options": "Opciones de desacoplamiento",
		"sort": "Ordenar",
		"submit": "Enviar",
		"submitting": "Enviando",
		"submit.changes": "Enviar cambios",
		"submit.new.user": "Entregar un nuevo usario",
		"the.unit.of.meter": "(Need Spanish) The unit of meter",
		"this.four.weeks": "Estos cuatro semanas",
		"timezone.no": "no zona horaria",
		"this.week": "Esta semana",
		"TimeSortTypes.decreasing": "decreciente",
		"TimeSortTypes.increasing": "creciente",
		"TimeSortTypes.meter": "valor del medidor o predeterminado",
		"today": "Hoy",
		"toggle.custom.slider": "deslizador personalizada",
		"toggle.link": "Alternar enlace de gráfico",
		"total": "Total",
		"true": "Verdad",
		"TrueFalseType.false": "no",
		"TrueFalseType.true": "sí",
		"undefined": "indefinido",
		"unit": "(Need Spanish) Unit",
		"UnitRepresentType.quantity": "(Need Spanish) quantity",
		"UnitRepresentType.flow": "(Need Spanish) flow",
		"UnitRepresentType.raw": "(Need Spanish) raw",
		"UnitType.unit": "(Need Spanish) unit",
		"UnitType.meter": "(Need Spanish) meter",
		"UnitType.suffix": "(Need Spanish) suffix",
		"unit.dropdown.displayable.option.none": "(Need Spanish) None",
		"unit.dropdown.displayable.option.all": "(Need Spanish) All",
		"unit.dropdown.displayable.option.admin": "(Need Spanish) admin",
		"unit.failed.to.create.unit": "(Need Spanish) Failed to create a unit.",
		"unit.failed.to.edit.unit": "(Need Spanish) Failed to edit unit.",
		"unit.input.error": "(Need Spanish) Input invalid so unit not created or edited.",
		"unit.none": "(Need Spanish) no unit",
		"unit.preferred.display": "(Need Spanish) Preferred Display:",
		"unit.represent": "(Need Spanish) Unit Represent:",
		"unit.sec.in.rate": "(Need Spanish) Sec in Rate:",
		"unit.submit.new.unit": "(Need Spanish) Submit New Unit",
		"unit.successfully.create.unit": "(Need Spanish) Successfully created a unit.",
		"unit.successfully.edited.unit": "(Need Spanish) Successfully edited unit.",
		"unit.suffix": "(Need Spanish) Suffix:",
		"unit.type.of.unit": "(Need Spanish) Type of Unit:",
		"units": "(Need Spanish) Units",
		"unsaved.warning": "Tienes cambios sin guardar. ¿Estás seguro que quieres irte?",
		"update": "Actualizado",
		"updated.map.with.calibration": "Mapa actualizado con calibración renovada",
		"updated.map.without.calibration": "Actualización de un mapa sin calibrado",
		"updated.map.without.new.calibration": "Mapa actualizado",
		"updated.preferences": "Preferencias Actualizado",
		"upload.meters.csv": "Subir archivo de CSV medidores",
		"upload.new.map.with.calibration": "Cargado un nuevo mapa con calibración",
		"upload.new.map.without.calibration": "Cargado un nuevo mapa sin calibrar",
		"upload.readings.csv": "Subir archivo de las lecturas de  CSV",
		"used.so.far": "usado hasta ahora",
		"used.this.time": "usado esta vez",
		"users": "Usarios",
		"users.failed.to.create.user": "Fallo en la creación de el usario.",
		"users.failed.to.delete.user": "Fallo en el borrado de el usario.",
		"users.failed.to.edit.users": " Fallo en la edición de usuarios.",
		"users.successfully.create.user": "Usario creado con éxito.",
		"users.successfully.delete.user": "Usario borrado con éxito.",
		"users.successfully.edit.users": "Usarios editados con éxito.",
		"view.groups": "Ver grupos",
		"visit": " o visite nuestra ",
		"website": "sitio web",
		"week": "Semana",
		"yes": "sí",
		"yesterday": "Ayer",
		"you.cannot.create.a.cyclic.group": "No se puede crear un grupo cíclico"
	}
}

export default localeData;
